
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Button,
  Alert
} from "reactstrap";

import { isEmpty } from "lodash";
import UserDetails from "./UserDetails";
import PersonalDetails from "./PersonalDetails";
import NomineeDetails from "./Nominee/NomineeDetails";
import BankDetails2 from "./Bank/BankDetails2";
import BankDetails from "./Bank/BankDetails";
import SecurityDetails from "./SecurityDetails";
import AdditionalDetails from "./AdditionalDetails";
import Segments from "./Segments";
import { getClientId } from "../../store/eSignClientId/action";

import { checkModificationStatus, checkModificationStatusNew, getModificationAllData, getProfileBusinessDetailsApi, getProfileNomineeApi1, getProfileNomineeApi2, modification_profile } from "../../helpers/fakebackend_helper";
import { checkModificationStatusAll } from './../../helpers/fakebackend_helper';
import AddressDetails from "./AddressDetails";
import AnnualIncome from "./AnnualIncome";
import { toast } from "react-toastify";
import { getMarginOverview } from "../../store/actions";

const Profile = () => {
  const [businessInfo, setBusinessInfo] = useState({});
  const [nominee, setNominee] = useState([]);
  const [modal_signUpModals, setmodal_signUpModals] = useState(false);
  const dispatch = useDispatch();
  const search = useLocation().search;
  const { profileDetails } = useSelector((state) => ({
    profileDetails: state.ProfileDetails.userProfile,
  }));
  const { ClientID } = useSelector((state) => ({
    ClientID: state.ClientID.ClientID
  }));
  const [modificationNotification, setModificationNotification] = useState(false);
  const [modificationResponse, setModificationResponse] = useState({});
  const [modificationMessage, setModificationMessage] = useState([]);
  const [rejection, setRejection] = useState([]);
  const client_code = localStorage.getItem("client_id");
  const client_token = localStorage.getItem("client_token");
  const redirect = localStorage.getItem("redirect");
  const [alertVisibility, setAlertVisibility] = useState([]);
  const [visible, setVisible] = useState(true);

  const generatedKey = JSON.parse(localStorage.getItem("genApiKeyResult"));
  const encryptKey = JSON.parse(localStorage.getItem("encryptKey"));
  const { availableMargin } = useSelector((state) => ({
    availableMargin: state.MarginOverview,
  }));

  function convertValue(value) {
    // Check if the value is negative
    if (value < 0) {
      // If negative, convert it to positive
      return Math.abs(value);
    } else {
      // If positive, convert it to negative
      return -value;
    }
  }
  let convertedValue = convertValue(availableMargin?.availableMargin[0]?.Final1);
 





  // const onDismiss = (index) => {
  //   setVisible(false)
  //   setModificationNotification(false)

  // };
  // const onDismiss1 = (index) => {
  //   setVisible(false)
  //   setModificationNotification(false)
  // };
  const onDismiss = (index) => {
    const updatedVisibility = [...alertVisibility];
    updatedVisibility[index] = false; // Mark the dismissed alert as not visible
    setAlertVisibility(updatedVisibility);
  };
  console.log(modificationMessage, 'modificationMessage');

  // if(client_code == undefined || client_code == 'NULL' || client_code == "" || client_token == undefined || client_token == 'NULL' || client_token == ""){

  //   const client_code = new URLSearchParams(search).get('client_id');
  //   const token = new URLSearchParams(search).get('token');
  //   const symphony_token = new URLSearchParams(search).get('symphony_token');
  //   // console.log(token)
  //   let decodedStringAtoB = atob(client_code);
  //   let decodedStringAtoc = atob(token); // TODO seve to client_token
  //   let decodedStringAtod = atob(symphony_token); // TODO seve to client_token

  //     localStorage.setItem('client_id', decodedStringAtoB);
  //     localStorage.setItem('client_token', decodedStringAtoc); // TODO seve to client_token
  //     localStorage.setItem('symphony_token', decodedStringAtod); // TODO seve to client_token


  // }

  if (profileDetails.length > 0) {
    window.history.replaceState({}, document.title, 'profile');
  }

  useEffect(() => {
    console.log('FIRST', client_code)

    //Modification API dkyc data copy
    /* try {
      modification_profile(client_code)
      .then((response) => {
        console.log('modification_profilemodification_profile',response);
      })
      .catch((err) => {
        console.log('catch ERROR',err);
      });
    }catch(error){
      console.log('ERROR ',error);
    } */
    //Modification API dkyc data copy
    const info = {
      client_code: client_code,
      pan_no: !isEmpty(profileDetails[0]) && profileDetails[0].PANGIR.trim(),
    };
    dispatch(getClientId(info));
  }, [dispatch, client_code, profileDetails]);

  /* useEffect(() => {
    const info = {
      client_code: client_code,
      pan_no: !isEmpty(profileDetails[0]) && profileDetails[0].PANGIR,
    };
    getProfileBusinessDetailsApi(info)
      .then((response) => {
        //console.log(response);
        if (response.success === true) {
          setBusinessInfo(true);
        }
        //props.sendData(modalMobile);
      })
      .catch((err) => {
        ////console.log(err);
      });
  }, [profileDetails, client_code]); */

  useEffect(() => {
    if (!isEmpty(profileDetails) && profileDetails[0].DPID.trim() === "12033500") {
      const panData = !isEmpty(profileDetails[0]) && profileDetails[0].PANGIR.trim();
      getProfileNomineeApi1({ PanNo: panData, client_token: client_token })
        .then((response) => {
          //console.log(response);
          if (response.status === "success" && response.data.length > 0) {
            setNominee(response.data);
          } else {
            setNominee([]);
          }
          //props.sendData(modalMobile);
        })
        .catch((err) => {
          ////console.log(err);
        });
    } else {
      const panData = !isEmpty(profileDetails[0]) && profileDetails[0].PANGIR.trim();
      getProfileNomineeApi2({ PanNo: panData, client_token: client_token })
        .then((response) => {
          //console.log(response);
          if (response.success === true) {
            setNominee(response.data);
          } else {
            setNominee([]);
          }
          //props.sendData(modalMobile);
        })
        .catch((err) => {
          ////console.log(err);
        });
    }
  }, [profileDetails]);


  //console.log(nominee);



  function tog_signUpModals() {
    setmodal_signUpModals(!modal_signUpModals);
  }

  const [activeTab, setActiveTab] = useState(redirect == 'segment'?'3':redirect == 'nominee'?'4':redirect == 'bank'?'5':redirect == 'address'?'6':redirect == 'income'?'7':'1');

  const tabChange = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  useEffect(() => {
    // checkModificationStatusNew({ client_code: client_code })
    //   .then((response) => {
    //     if (response.status === true) { } else {
    //       setModificationNotification(false);
    //     }
    //   })
    //   .catch((err) => {
    //    console.log(err);
    //   })
    checkModificationStatus({
      client_code: client_code,
      // pan_no: !isEmpty(profileDetails[0]) && profileDetails[0].PANGIR.trim(),
      // field_name: "all",
      // esign_flag: "YES",
    })
      .then((response) => {
        ////console.log(response);
        if (response.status == true) {
          if (visible) {
            setModificationNotification(true);
          }
          setModificationResponse(response);
          console.log(response?.data?.modification_for[0], 'dataaaaaaa');
          // const dataKey = Object.keys(response?.data?.modification_for);
          // console.log(dataKey, 'datakey');
          setModificationMessage(response?.data?.modification_for)
          setRejection(response?.data?.rejection)
          ////console.log(response);

        } else {
          setModificationNotification(false);
          //console.log(response);

        }
        //props.sendData(modalMobile);
      })
      .catch((err) => {
        toast('something went wrong')
      });

  }, [activeTab]);

  useEffect(()=>{
    setTimeout(() => {
    localStorage.removeItem("redirect");
      
    }, 3000);
  },[activeTab])
  useEffect(() => {
    dispatch(getMarginOverview({ apiKey: generatedKey, code: encryptKey}));
  }, [dispatch, activeTab]);

return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <UserDetails data={!isEmpty(profileDetails[0]) && profileDetails[0]} />
            <Col xxl={9} xl={9} lg={8}>


              {modificationNotification && modificationMessage?.map((message, index) => (
                (message?.is_esign == 1 && message?.is_send_to_ld == 0) &&
                <>
                  <Alert color="info" key={index} isOpen={alertVisibility[index]} toggle={() => onDismiss(index)}>
                    Your request for modification of <strong>{message.entry_type === "nominee_1" ? "Nominee" : message.entry_type === "annual_income" ? "Annual Income" : message.entry_type === "primary_bank" ? "Primary Bank" : message.entry_type === "dpscheme" ? "DP Scheme" : message.entry_type === "mobile" ? "Mobile Number" : message.entry_type === "ddpi" ? "DDPI" : message.entry_type === "bank" ? "Bank" : message.entry_type === "email" ? "Email Id" : message.entry_type === "segment" ? "Segment" : message.entry_type}</strong> has been raised and is pending verification.
                  </Alert>
                </>
              ))}



              {modificationNotification && rejection?.map((message, index) => (
                <>
                  <Alert color="danger" key={index} isOpen={alertVisibility[index]} toggle={() => onDismiss(index)}>
                    {'Your modification request was rejected.' + ' ' + message?.reason + '.' + ' ' + 'Please re-submit.'}
                  </Alert>
                </>
              ))}
              <Card className="">
                <CardHeader className="profile-active-tab-border">
                  <Nav
                    className="nav-tabs-custom card-header-tabs border-bottom-0 my-1 statement-menu"
                    role="tablist"

                  >
                    <NavItem>
                      <NavLink
                        //className={classnames({ active: activeTab === "1" })}
                        className={`${activeTab === "1"
                          ? "profile-active-tab-active"
                          : "profile-active-tab"
                          } text-5 nav-link`}
                        onClick={() => {
                          tabChange("1");
                        }}
                      >
                        <i className="fas fa-home"></i>
                        Personal Details
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        to="#"
                        //className={classnames({ active: activeTab === "2" })}
                        className={`${activeTab === "2"
                          ? "profile-active-tab-active"
                          : "profile-active-tab"
                          } text-5 nav-link`}
                        onClick={() => {
                          tabChange("2");
                        }}
                        type="button"
                      >
                        <i className="far fa-user"></i>
                        Additional Details
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        to="#"
                        //className={classnames({ active: activeTab === "4" })}
                        className={`${activeTab === "3"
                          ? "profile-active-tab-active"
                          : "profile-active-tab"
                          } text-5 nav-link`}
                        onClick={() => {
                          tabChange("3");
                        }}
                        type="button"
                      >
                        <i className="far fa-envelope"></i>
                        Segments
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        to="#"
                        //className={classnames({ active: activeTab === "3" })}
                        className={`${activeTab === "4"
                          ? "profile-active-tab-active"
                          : "profile-active-tab"
                          } text-5 nav-link`}
                        onClick={() => {
                          tabChange("4");
                        }}
                        type="button"
                      >
                        <i className="far fa-envelope"></i>
                        Nominee Details
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        to="#"
                        //className={classnames({ active: activeTab === "4" })}
                        className={`${activeTab === "5"
                          ? "profile-active-tab-active"
                          : "profile-active-tab"
                          } text-5 nav-link`}
                        onClick={() => {
                          tabChange("5");
                        }}
                        type="button"
                      >
                        <i className="far fa-envelope"></i>
                        Bank Details
                      </NavLink>
                    </NavItem>
                    {/* <NavItem>
                      <NavLink
                        to="#"
                        //className={classnames({ active: activeTab === "4" })}
                        className={`${activeTab === "6"
                          ? "profile-active-tab-active"
                          : "profile-active-tab"
                          } text-5 nav-link`}
                        onClick={() => {
                          tabChange("6");
                        }}
                        type="button"
                      >
                        <i className="far fa-envelope"></i>
                        Address Details
                      </NavLink>
                    </NavItem> */}

                    <NavItem>
                      <NavLink
                        to="#"
                        //className={classnames({ active: activeTab === "4" })}
                        className={`${activeTab === "7"
                          ? "profile-active-tab-active"
                          : "profile-active-tab"
                          } text-5 nav-link`}
                        onClick={() => {
                          tabChange("7");
                        }}
                        type="button"
                      >
                        <i className="far fa-envelope"></i>
                        Annual Income
                      </NavLink>
                    </NavItem>

                  </Nav>
                </CardHeader>
                <CardBody>
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      <PersonalDetails data={!isEmpty(profileDetails[0]) && profileDetails[0]} activeTab={activeTab} Balance={convertedValue} />
                    </TabPane>
                    <TabPane tabId="2">
                      <AdditionalDetails activeTab={activeTab} Balance={convertedValue}/>
                    </TabPane>
                    <TabPane tabId="3">
                      <Segments activeTab={activeTab}  />
                    </TabPane>
                    <TabPane tabId="4">
                      <NomineeDetails activeTab={activeTab} data={nominee} data2={!isEmpty(profileDetails[0]) && profileDetails[0]} Balance={convertedValue} />
                    </TabPane>
                    <TabPane tabId="5">
                      <BankDetails data={!isEmpty(profileDetails[0]) && profileDetails} activeTab={activeTab} Balance={convertedValue} />
                    </TabPane>
                    <TabPane tabId="6">
                      <AddressDetails data={!isEmpty(profileDetails[0]) && profileDetails} activeTab={activeTab} Balance={convertedValue} />
                    </TabPane>
                    <TabPane tabId="7">
                      <AnnualIncome data={!isEmpty(profileDetails[0]) && profileDetails} activeTab={activeTab} Balance={convertedValue} />
                    </TabPane>
                    {/* <TabPane tabId="6">
                      <SecurityDetails activeTab={activeTab} />
                    </TabPane> */}
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Profile;
