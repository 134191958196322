import React, { useState } from "react";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import copy from 'copy-to-clipboard';
import { isEmpty } from "lodash";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Button,
} from "reactstrap";

import avatar1 from "../../assets/images/users/avatar-1.jpg";

const UserDetails = (props) => {
  const { data } = props;
  const [copy1, setCopy1] = useState(false);
  const [copy2, setCopy2] = useState(false);
  const client_type = localStorage.getItem("client_type");
  ////console.log(data);
  // const myFunction = (data, en) => {
  //   navigator.clipboard.writeText(data);
  //   if (en === "pan") {
  //     setCopy({ ...copy, cli: true });
  //   } else {
  //     setCopy({ ...copy, pan: true });
  //   }

  //   setTimeout(() => {
  //     setCopy({ ...copy, pan: false, cli: false });
  //   }, 3000);
  // };
  const copyToClipboard = () => {
    //console.log("hello")
    copy(data.OOWNCODE.trim());

    setCopy1(true);
    setTimeout(() => {
      setCopy1(false);
    }, 3000);
    //alert(`You have copied "${copyText}"`);
  }

  const copyToClipboard2 = () => {
    //console.log("hello")
    copy(data.PANGIR.trim());

    setCopy2(true);
    setTimeout(() => {
      setCopy2(false);
    }, 3000);
    //alert(`You have copied "${copyText}"`);
  }


  return (
    <Col xxl={3} xl={3} lg={4}>
      <Row>
        <Col lg={12} md={6}>
          <Card>
            <CardBody className="p-3">
              <div>
                <div className="text-center">
                  <div className="profile-user position-relative d-inline-block mx-auto  mb-4">
                    {/* <img
                      src={(!isEmpty(data) && data.LOGO) ? data.LOGO : (!isEmpty(data) && !data.LOGO) ? data.FIBSACCT.charAt(0).toLocaleUpperCase() : avatar1}
                      className="rounded-circle avatar-xl img-thumbnail user-profile-image"
                      alt="user-profile"
                    /> */}
                    {!isEmpty(data) && data.LOGO ? (
                      <img
                        src={!isEmpty(data) && data.LOGO ? data.LOGO : avatar1}
                        className="rounded-circle avatar-xl img-thumbnail user-profile-image"
                        alt="user-profile"
                      />
                    ) : (
                      <div
                        className="rounded-circle avatar-xl img-thumbnail user-profile-image"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <p
                          style={{
                            margin: "0",
                            fontSize: "60px",
                            fontWeight: "600",
                            color: "#5367fc",
                          }}
                        >
                          {!isEmpty(data) &&
                            !data.LOGO &&
                            data.FIBSACCT.charAt(0).toLocaleUpperCase()}
                        </p>
                      </div>
                    )}
                    {/* profile image edit option commented */}
                    {/* <div className="avatar-xs p-0 rounded-circle profile-photo-edit">
                      <Input
                        id="profile-img-file-input"
                        type="file"
                        className="profile-img-file-input"
                      />
                      <Label
                        htmlFor="profile-img-file-input"
                        className="profile-photo-edit avatar-xs"
                      >
                        <span className="avatar-title rounded-circle bg-light text-body">
                          <i className="ri-pencil-line text-color2 fs-4"></i>
                        </span>
                      </Label>
                    </div> */}
                  </div>
                </div>
                <Row className={"mb-1"}>
                  <Col xxl={5} xl={5} lg={5} md={5} xs={5} className="pe-0">
                    <p className="text-color1 text-13">Full Name:</p>
                  </Col>
                  <Col xxl={7} xl={7} lg={7} md={7} xs={7}>
                    <h6 className="text-color1 text-13">
                      {!isEmpty(data) && data.FIBSACCT}
                    </h6>
                  </Col>
                </Row>
                <Row className={"mb-1"}>
                  <Col xxl={5} xl={5} lg={5} md={5} xs={5} className="pe-0">
                    <p className="text-color1 text-13">Client Code:</p>
                  </Col>
                  <Col xxl={7} xl={7} lg={7} md={7} xs={7}>
                    <div className="d-flex">
                      <h6 className="text-color1 text-13">
                        {!copy1
                          ? !isEmpty(data) && 'XXXXX' + data.OOWNCODE.trim().slice(-4)
                          : "Copied!"}
                      </h6>
                      <button
                        onClick={copyToClipboard}
                        className="copy-to-clip-button"
                      >
                        <i className="ri-file-copy-line text-4 text-color7"></i>
                      </button>
                    </div>
                  </Col>
                </Row>
                <Row className={"mb-1"}>
                  <Col xxl={5} xl={5} lg={5} md={5} xs={5} className="pe-0">
                    <p className="text-color1 text-13">DOB:</p>
                  </Col>
                  <Col xxl={7} xl={7} lg={7} md={7} xs={7}>
                    <h6 className="text-color1 text-13">
                      {!isEmpty(data) &&
                        dayjs(data.DDATEOFBIRTH.date).format("DD MMM YYYY")}
                    </h6>
                  </Col>
                </Row>
                <Row className={"mb-1"}>
                  <Col xxl={5} xl={5} lg={5} md={5} xs={5} className="pe-0">
                    <p className="text-color1 text-13">PAN Number:</p>
                  </Col>
                  <Col xxl={7} xl={7} lg={7} md={7} xs={7}>
                    <div className="d-flex">
                      <h6 className="text-color1 text-13">
                        {!copy2 ? !isEmpty(data) && 'XXXXXX' + data.PANGIR.trim().slice(-4) : "Copied!"}
                      </h6>
                      <button
                        onClick={copyToClipboard2}
                        className="copy-to-clip-button"
                      >
                        <i className="ri-file-copy-line text-4 text-color7"></i>
                      </button>
                    </div>
                  </Col>
                </Row>
                <div className="">
                  <p className="text-color1 text-13 mb-1">Address:</p>
                  <h6 className="text-color1 text-13">
                    {!isEmpty(data) &&
                      `${data.ADDRESS1}, ${data.ADDRESS2}, ${data.ADDRESS3}, ${data.CITY}, ${data.COUNTRY}, ${data.CSTATE}-${data.PINCODE}`}
                  </h6>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        {
          client_type == "Bigul" ?
            <Col lg={12} md={6}>
              <Card>
                <CardBody>
                  <div className="vstack gap-1">
                    <div className="d-flex align-items-center">
                      <div className="profile-round-dot d-block flex-shrink-0 me-3">
                        <span className="avatar-title rounded-circle fs-4 background-color3"></span>
                      </div>
                      <div className="flex-grow-1">
                        <h5 className="text-4 m-1 text-color4">My Plans</h5>
                      </div>
                      <div className="flex-shrink-0">
                        <div className="d-flex align-items-center gap-1">
                          <Link
                            className="btn btn-sm text-color2"
                            to="/subscription/plans"
                          >
                            <i
                              className="ri-arrow-right-s-line align-bottom"
                              style={{ fontSize: "20px" }}
                            ></i>
                          </Link>
                        </div>
                      </div>
                    </div>


                    {/* <div className="d-flex align-items-center">
                  <div className="profile-round-dot d-block flex-shrink-0 me-3">
                    <span className="avatar-title rounded-circle fs-4 background-color7"></span>
                  </div>
                  <div className="flex-grow-1">
                    <h5 className="text-4 m-1 text-color4">My Algos</h5>
                  </div>
                  <div className="flex-shrink-0">
                    <div className="d-flex align-items-center gap-1">
                      <Link className="btn btn-sm text-color2" to="/myalgos">
                        <i
                          className="ri-arrow-right-s-line align-bottom"
                          style={{ fontSize: "20px" }}
                        ></i>
                      </Link>
                    </div>
                  </div>
                </div> */}
                    {/* <div className="d-flex align-items-center">
                  <div className="profile-round-dot d-block flex-shrink-0 me-3">
                    <span className="avatar-title rounded-circle fs-4 background-color8"></span>
                  </div>
                  <div className="flex-grow-1">
                    <h5 className="text-4 m-1 text-color4">Become a BA</h5>
                  </div>
                  <div className="flex-shrink-0">
                    <div className="d-flex align-items-center gap-1">
                      <button type="button" className="btn btn-sm text-color2">
                        <i
                          className="ri-arrow-right-s-line align-bottom"
                          style={{ fontSize: "20px" }}
                        ></i>
                      </button>
                    </div>
                  </div>
                </div> */}
                  </div>
                </CardBody>
              </Card>
            </Col>
            :
            <Col lg={12} md={6}>
              <Card>
                <CardBody>
                  <div className="vstack gap-1">


                    <div className="d-flex align-items-center">
                      <div className="profile-round-dot d-block flex-shrink-0 me-3">
                        <span className="avatar-title rounded-circle fs-4 background-color3"></span>
                      </div>
                      <div className="flex-grow-1">
                        <h5 className="text-4 m-1 text-color4">Switch to Bigul</h5>
                      </div>
                      <div className="flex-shrink-0" onClick={() => alert('pending')}>
                        <div className="d-flex align-items-center gap-1">
                          <div style={{ fontSize: '18px' }} className="ri-arrow-right-s-line align-bottom btn btn-sm text-color2"></div>
                        </div>
                      </div>
                    </div>



                  </div>
                </CardBody>
              </Card>
            </Col>
        }
      </Row>
    </Col>
  );
};

export default UserDetails;
