import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import { Navigate, Route, useLocation } from "react-router-dom";
//redux
import { useSelector } from "react-redux";
//reuseable component
import BreadCrumb from "../../Components/CustomComponents/BreadCrumb";

import { isEmpty } from "lodash";

//Dashboard components
import MyPortfolio from "./MyPortfolio";
import MarginOverview from "./MarginOverview";
import Widgets from "./Widgets";
import Highlights from "./Highlights";
import MyInvestedAlgos from "./MyInvestedAlgos";
import SuggestedAlgos from "./SuggestedAlgos";
import ActiveFeatures from "./ActiveFeatures";
import ELearningSlider from "./ELearningSlider";
import AddonsSlider from "./AddonsSlider";
import Ambassador from "./Ambassador";
import FooterText from "./FooterText";
import ActiveFeatures1 from './ActiveFeatures1';
import SuggestedAlgos1 from './SuggestedAlgos1';
import MyPortfolio1 from './MyPortfolio1';
import Loader from '../../Components/Common/Loader';

// IMAGE

const BigulDashboard = () => {
  document.title = "Bigul Admin & Dashboard";
  const { profileDetails } = useSelector((state) => ({
    profileDetails: state.ProfileDetails.userProfile,
  }));
  const [loading, setLoading] = useState(false);
  const search = useLocation().search;

  const generatedKey = JSON.parse(localStorage.getItem('genApiKeyResult'));
  const encryptKey = JSON.parse(localStorage.getItem('encryptKey'));
  const client_type = localStorage.getItem("client_type");

  // const expires = localStorage.getItem("expires");

  const client_id_check = new URLSearchParams(search).get('client_id');
  const url1 = new URLSearchParams(search).get('redirect_back');
  const url = url1?.split('?')[0]
  const baseUrl = client_type == 'Bonanza'?"https://dashboard.bonanzaonline.com/":"https://dashboard.bigul.co/";
  const result = url?.replace(baseUrl, "");
  console.log(result, 'result');

  let urlParams = new URLSearchParams(url1?.split('?')[1]);
  let redirect = urlParams.get('tab');
  console.log(redirect, 'tab');

  if (result == 'profile') {
    localStorage.setItem('redirect', redirect);
  }

  if (client_id_check == undefined || client_id_check == 'NULL' || client_id_check == "") {
    var ii = 0;
  }
  else {
    return <Navigate to={{
      pathname: client_type === "Bigul" ? url == null ? '/dashboard' : '/' + result : '/family'
    }}
    />
  }

  //   useEffect(() => {
  //    if(expires){
  //     const timestamp = expires;

  //     // Convert timestamp to milliseconds
  //     const timestampMs = timestamp * 1000;

  //     // Get the current time in milliseconds
  //     const currentTimeMs = Date.now();

  //     // Check if the current time exceeds the given timestamp
  //     if (currentTimeMs > timestampMs) {
  //       return window.open("https://bigul.app/sso-login-middleware", "_self");
  //     } else {
  //       // alert()
  //     }
  //   }
  // }, []);

  // const token = new URLSearchParams(search).get('token');
  // const symphony_token = new URLSearchParams(search).get('symphony_token');
  // console.log(token)
  // let decodedStringAtoB = atob(client_id);
  // let decodedStringAtoc = atob(token); // TODO seve to client_token
  // let decodedStringAtod = atob(symphony_token); // TODO seve to client_token

  // let encodedStringBtoA = btoa('Y0909756');
  // //console.log(encodedStringBtoA);
  // let encodedStringBtoC = btoa('eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6IlkwOTA5NzU2IiwiTWlkZGxlV2FyZV9NYXBwaW5nIjoiMTEiLCJNaWRkbGVXYXJlX0pzb25EYXRhIjoie1wiUHVibGljS2V5NHBlbVwiOlwiZXlKaGJHY2lPaUpJVXpJMU5pSXNJblI1Y0NJNklrcFhWQ0o5LmV5SjFjMlZ5U1VRaU9pSlpNRGt3T1RjMU5pSXNJbTFsYldKbGNrbEVJam9pUWs5T1FVNWFRU0lzSW5OdmRYSmpaU0k2SWxkRlFpSXNJbWxoZENJNk1UWTRPREF4TnpjeE5pd2laWGh3SWpveE5qZzRNVEEwTVRFMmZRLi0xRGgycHJMb1Zsbnp1dWZMTW9jX3ZHMnNOWnB5d0ZaS0EtR0FteGNjNm9cIixcIkxvZ2luSURcIjpcIlkwOTA5NzU2XCIsXCJMYXN0TG9naW5UaW1lXCI6XCI2LzI5LzIwMjMgMTE6MTg6MzcgQU1cIn0iLCJEZXZpY2VJZCI6IjEyMzEyMzEyMyIsIkRldmljZVR5cGUiOiJBbmRyb2lkIiwiTG9naW5JZCI6IlkwOTA5NzU2IiwibmJmIjoxNjg4MDE3NzE3LCJleHAiOjE2ODgxMDQxMTcsImlhdCI6MTY4ODAxNzcxN30.jqW2Lr0cfde0doV_UIVIlY9-MjRzpVK-I6qomGOBwKw');
  // //console.log(encodedStringBtoC);

  // if(client_id !== null) {
  //   localStorage.setItem('client_id', decodedStringAtoB);
  //   localStorage.setItem('client_token', decodedStringAtoc); // TODO seve to client_token
  //   localStorage.setItem('symphony_token', decodedStringAtod); // TODO seve to client_token
  //   return <Navigate to={{ pathname: "/dashboard"}} />
  // }




  if (loading) {
    return (
      <Loader class={"mt-5"} />
    )
  }


  // if(profileDetails.length > 0){
  //   // localStorage.removeItem('client_id');
  //   var get_user_datat = profileDetails[0].OOWNCODE;
  //   console.log('profileDetails',profileDetails);
  //   if(decodedStringAtoB != get_user_datat){
  //     console.log('get_user_datat',get_user_datat);
  //     localStorage.setItem('client_id', get_user_datat);
  //   }
  // }

  return (
    <React.Fragment>
      <div className="page-content pb-0">
        <Container fluid>
          <BreadCrumb
            title="Overview"
            titleMain={`Welcome, ${!isEmpty(profileDetails[0]) ? profileDetails[0].FIBSACCT.split(' ')[0] : ""}!`}
            titleSecondary={`Here's what's happening with your account today.`}
            pageTitle="Dashboard"
            to='/dashboard'
          />
          <Row className="mt-3 mb-5 margin-mobile-row2">
            <Widgets />
          </Row>
          <Row className="spacing-remove mb-5 margin-mobile-row">
            {/* <MyPortfolio /> */}
            <MyPortfolio1 profileDate={!isEmpty(profileDetails[0]) ? profileDetails[0] : {}} generatedKey={generatedKey} encryptKey={encryptKey} />
            <MarginOverview generatedKey={generatedKey} encryptKey={encryptKey} />
            <Highlights />
          </Row>
          {/* <Row className="">
            <MyInvestedAlgos />
            <SuggestedAlgos />
          </Row> */}
        </Container>
      </div>
      {/* <div
        className="page-content"
        style={{ marginTop: "0", paddingTop: "0", paddingBottom: "0" }}
      >
        <ActiveFeatures />
      </div> */}
      {
        client_type == "Bigul" &&
        <Container fluid className="mb-5">
          <Row className="px-2 my-4 margin-mobile-row-non margin-tab-row-non">
            <ActiveFeatures1 />
          </Row>
          <Row className="spacing-bottom-m my-3 margin-top-remove">
            <AddonsSlider />
          </Row>
          <Row className="mb-4 margin-mobile-row-non">
            <SuggestedAlgos1 />

          </Row>
          <Row className="">
            <ELearningSlider />
          </Row>
        </Container>
      }

    </React.Fragment>
  );
};

export default BigulDashboard;
