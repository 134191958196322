import React, { useState, useEffect } from "react";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import copy from 'copy-to-clipboard';
import Share from './../../../../assets/images/Share.svg'
import {
    Input,
    Modal,
    Card,
    CardBody,
    ModalBody,
    ModalHeader,
    Label,
} from "reactstrap";
import {
    EmailIcon,
    WhatsappIcon,
    LineIcon,
    LinkedinIcon,
    TwitterIcon,
    FacebookIcon,
} from "react-share";
import {
    EmailShareButton,
    WhatsappShareButton,
    LineShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    FacebookShareButton,
} from "react-share";
//redux
import { useSelector, useDispatch } from "react-redux";
//action
import { selectedPlanApi } from "../../../../helpers/fakebackend_helper";
import { addToCart } from "../../../../store/cart/action";
import axios from "axios";
import { OPEN_DIGILOKCKER_FAMILY } from "../../../../helpers/url_helper";
// TODO Ui and design and functions.
const Share1 = (props) => {
    const dispatch = useDispatch();

    const client_type = localStorage.getItem("client_type");
    const client_token = localStorage.getItem("client_token");

    const { newData } = props;
    const [copy1, setCopy1] = useState(false);
    const [data, setdata] = useState(false);
    const [staus, setStatus] = useState(false);
    const [modal_signUpModals, setmodal_signUpModals] = useState(false);

    const tog_signUpModals = () => {
        setStatus(true)
        const payload = {
            client_code: newData?.oowncode.trim(),
            client_token: client_token,
            client_type: client_type

        }
        const headers = {
            'Content-Type': 'application/json',
        };
        axios.post(OPEN_DIGILOKCKER_FAMILY, payload, { headers: headers })
            .then(res => {
                if (res?.status == true) {
                    setStatus(false)

                    setdata(res?.link)
                    setmodal_signUpModals(!modal_signUpModals);
                }
                else {
                    setStatus(false)
                    toast(res?.message || 'Something went wrong')
                }
            })
            .catch(error => {
                setStatus(false)
                toast(error?.message || 'something went wrong')

            });

        // 
    };



    const copyToClipboard = () => {

        copy(data);

        setCopy1(true);
        setTimeout(() => {
            setCopy1(false);
        }, 3000);
        //alert(`You have copied "${copyText}"`);
    }
    const { profileDetails } = useSelector((state) => ({
        profileDetails: state.ProfileDetails.userProfile,
    }));

    const mobile = profileDetails[0]?.MOBILE.trim().slice(-4);

    const myFunction = (data) => {
        navigator.clipboard.writeText(data);
        setCopy1(true);
        setTimeout(() => {
            setCopy1(false);
        }, 3000);
    };

    return (
        <React.Fragment>
            <button
                type="button"
                style={{ 
                    width: '100%',
                    	background: '#5367FC',
                    color: '#fff',
                    justifyContent:'center',
                    height:'45px',
                    fontSize:'14px',borderRadius:'8px'
                   
	
                   
                    }}
                className="mt-0 input-group-text text-5 ref-and-earn-button text-uppercase"
                // onClick={() => tog_signUpModals()}
                onClick={() => staus ? null : tog_signUpModals()} // Prevent action when disabled
                disabled={staus} // Disable button based on 'staus'


            >
                {
                    staus ?
                        <p>...</p>
                        :<p>Share link</p>
                        // <img src={Share} style={{ height: '20px', width: '20px' }} />
                }

            </button>
            <Modal
                id="signupModals"
                tabIndex="-1"
                isOpen={modal_signUpModals}
                backdropClassName="background-color4"
                className="share-model-middle"
                toggle={() => {
                    tog_signUpModals();
                }}
                centered
                style={{ width: "350px" }}
            >
                <ModalHeader
                    className="px-4 py-4"
                    toggle={() => {
                        setmodal_signUpModals(false);
                    }}
                >
                    <h4
                        className="text-2 text-color7 spacing-remove-bottom"
                        style={{ margin: "auto 0" }}
                    >
                        Share the link
                    </h4>
                </ModalHeader>

                <div className="d-flex px-4 justify-content-center mb-2">
                    <h4
                        className="text-color12 background-color11 p-3 text-1 compleate-order-btn"
                        style={{ cursor: "copy", border: "1px dashed #f0e8e8" }}
                        onClick={() => {
                            myFunction(data);
                        }}
                    >
                        {!copy1 ? data : "Copied!"}
                    </h4>
                </div>
                {/* <h4 className="text-center text-10  mb-3">Share with</h4> */}
                <div className="d-flex px-4 justify-content-center mb-4 align-items-center">

                    <WhatsappShareButton
                        url={`Guess what? Click the link ${data} to validate the family relationship using your mobile number XXXXXX${mobile} and/or email ID ${profileDetails[0]?.EMAIL.trim()} for your Bonanza Demat and Trading Account associated with client code ${profileDetails[0]?.OOWNCODE.trim()}. For any queries, feel free to contact us at customercare@bonanzaonline.com. Thank you for your prompt attention. Best regards, Team Bonanza.`}
                        quote="link"
                        className="Demo__some-network__share-button me-2"
                    >
                        <WhatsappIcon size={40} round />
                    </WhatsappShareButton>

                    <EmailShareButton
                        url={`
        Dear Customer,

        Please click the link below to validate the family relationship with the mobile number XXXXXX${mobile} and/or email ${profileDetails[0]?.EMAIL.trim()} for your Bonanza Demat and Trading Account associated with Client Code ${profileDetails[0]?.OOWNCODE.trim()}.
Validation link ${data}
For any queries, feel free to contact us at customercare@bonanzaonline.com. Thank you for your prompt attention.

        Best regards,
        Team Bonanza.
    `}
                        quote="link"
                        className="Demo__some-network__share-button me-2"
                    >
                        <EmailIcon size={40} round bgStyle={{ fill: "#6464ff" }} />
                    </EmailShareButton>

                    {/* <LinkedinShareButton
                        url={data}
                        quote="link"
                        className="Demo__some-network__share-button me-2"
                    >
                        <LinkedinIcon size={40} round />
                    </LinkedinShareButton>

                    <TwitterShareButton
                        url={data}
                        quote="link"
                        className="Demo__some-network__share-button me-2"
                    >
                        <TwitterIcon size={40} round />
                    </TwitterShareButton> */}
                    <button
                        //className="input-group-text text-5 ref-and-earn-button text-uppercase"
                        type="button"
                        className="input-group-text"
                        style={{
                            border: "1px dashed #e7dddd",
                            width: "40px",
                            height: "40px",
                            padding: "5px",
                        }}
                        // onClick={() => {
                        //   myFunction(data);
                        // }}
                        onClick={copyToClipboard}
                    >
                        <i className="ri-file-copy-line text-color7 fs-4 mx-auto my-0"></i>
                    </button>
                </div>
            </Modal>
        </React.Fragment>
    );
};

export default Share1;
