import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import Share from "../../Components/CustomComponents/Models/share/Share";
import axios from "axios";
import Encrypted from "../Dashboard/Encripted";
import { DIGIO_FAMILY_SUBMITION, DIGIO_STATUS_FAMILY, MOD_EKYC_NEW, OPEN_DIGILOKCKER_FAMILY } from "../../helpers/url_helper";
import { toast } from "react-toastify";
import Loader from "../../Components/Common/Loader";
import TableContainer1 from "../../Components/CustomComponents/TableContainer1";
import { useLocation } from "react-router-dom";
import Share1 from "../../Components/CustomComponents/Models/share/Share1";
import eyes from '../../assets/images/eyes.png'
import './Tooltip.css';
const FamilyTable = () => {
    const location = useLocation();
    var client_code = localStorage.getItem('client_id');
    var client_token = localStorage.getItem('client_token');
    var client_type = localStorage.getItem('client_type');
    const [accounts, setAccounts] = useState([]);
    const [loading, setLoading] = useState(true);
    const { profileDetails } = useSelector((state) => ({
        profileDetails: state.ProfileDetails.userProfile,
    }));
    const [checkStatus, setCheckStatus] = useState(false)
    const [data, setData] = useState([])
    const validRecords = accounts.filter(record => ((record.validate_status === 1 && record.relation !== null && record?.status == 0) || record?.primary == 'Done'));
    const selfCount = accounts?.filter(rel => rel.relation == 1);
    const spouceCount = accounts?.filter(rel => rel.relation == 2);
    const HUF = accounts?.filter(rel => rel.relation == 6);
    const fourDigit = HUF[0]?.pangir.trim()
    const fourthCharacter = fourDigit == undefined ? 'P' : fourDigit[3];
    console.log(validRecords, 'validRecords');
    console.log(accounts, 'accounts');

    useEffect(() => {
        setData([])
        setCheckStatus(false)
        setLoading(true);
        if (profileDetails[0]?.MOBILE?.length > 5) {
            fetchFamilyInfo();
        }
    }, [profileDetails]);



    const fetchFamilyInfo = async () => {
        const payload = {
            client_code: client_code,
            mobile: profileDetails[0]?.MOBILE,
        };

        try {
            const response = await axios.post(MOD_EKYC_NEW + '/get-family-info', { 'enc': Encrypted([payload]) });
            if (response.status) {

                setAccounts(response?.data);

                setLoading(false);
            } else {
                toast('Something went wrong');
                setLoading(false);
                setAccounts([]);
            }
        } catch (err) {
            toast('Something went wrong');
            setAccounts([]);
            setLoading(false);
        }
    };


    useEffect(() => {
        let intervalId;
        const callApi = () => {
            const payload = {
                client_code: data?.oowncode.trim(),
                client_token: client_token,
                client_type: client_type

            }
            const headers = {
                'Content-Type': 'application/json',

            };
            axios.post(DIGIO_STATUS_FAMILY, payload, { headers: headers })
                .then(res => {
                    if (res?.status) {
                        if (res?.digio_kyc_status !== 0 && data !== null) {
                            setCheckStatus(false)
                            fetchFamilyInfo()
                        }
                    }
                    else {
                        toast('something went wrong')
                    }
                })
                .catch(error => {
                    toast(error?.message || 'something went wrong')
                });
        };
        if (checkStatus && location.pathname.includes('family')) {
            intervalId = setInterval(callApi, 20000); // Call API every 10 seconds
        }
        return () => {
            clearInterval(intervalId); // Cleanup on unmount or condition change
        };
    }, [checkStatus]);


    const relationOptions = [
        { id: 1, name: "Self" },
        { id: 2, name: "Spouse" },
        { id: 3, name: "Dependent Parents" },
        { id: 4, name: "Dependent Child" },
        { id: 5, name: "HUF" },
        { id: 6, name: "AOP" },
    ];

    const handleRelationChange = (id, newRelation) => {
        if (newRelation == 6 && fourthCharacter == "P") {
            toast.error("For Relation AOP, Trading and demat account should be non- individual.");
            setAccounts(prevContacts =>
                prevContacts.map(contact =>
                    contact.oowncode === id ? { ...contact, relation: null } : contact // or use '' for empty
                )
            );
            return
        }
        setAccounts(prevContacts =>
            prevContacts.map(contact =>
                contact.oowncode === id ? { ...contact, relation: newRelation } : contact
            )
        );
    };

    const handleSubmit = () => {
        const hasMissingRelations = validRecords
            ?.filter(record => record.primary !== 'Done') // Filter out records with primary as 'Done'
            .some(record => record.relation == null || record.relation === 'Select');

        if (hasMissingRelations) {
            toast.error("Please select relation.");
            return;
        }
        if (selfCount?.length == 0) {
            toast.error('You must select one Self before submitting.');
            return;
        }

        if (selfCount?.length > 1) {
            toast.error('Relation Self already updated.');
            return;
        }
        if (spouceCount?.length > 1) {
            toast.error('Relation Spouse already updated..');

            return;
        }
        const selfDOB = new Date(selfCount[0]?.ddateofbirth);
        const dependentParents = validRecords.filter(record => record.relation == 3); // Assuming 3 is for "Dependent Parents"
        const dependentParents1 = validRecords.filter(record => record.relation == 4); // Assuming 3 is for "Dependent Parents"

        for (const parent of dependentParents) {
            const parentDOB = new Date(parent.ddateofbirth);
            const ageDifference = selfDOB.getFullYear() - parentDOB.getFullYear();
            const selfMonth = selfDOB.getMonth();
            const parentMonth = parentDOB.getMonth();
            const selfDate = selfDOB.getDate();
            const parentDate = parentDOB.getDate();
            if (dependentParents && ageDifference < 15 ||
                (ageDifference === 15 && (parentMonth < selfMonth ||
                    (parentMonth === selfMonth && parentDate <= selfDate)))) {
                toast.error(`Please update correction Relation.`);
                return
            }
        }
        for (const parent of (dependentParents1)) {
            const parentDOB = new Date(parent.ddateofbirth);
            const ageDifference = selfDOB.getFullYear() - parentDOB.getFullYear();
            const selfMonth = selfDOB.getMonth();
            const parentMonth = parentDOB.getMonth();
            const selfDate = selfDOB.getDate();
            const parentDate = parentDOB.getDate();

            // Check if the parent is less than 15 years older
            if (dependentParents1 && ageDifference > -15 ||
                (ageDifference === 15 && (parentMonth < selfMonth ||
                    (parentMonth === selfMonth && parentDate <= selfDate)))) {
                toast.error(`Please update correction Relation.`);
                return
            }
        }
        if (spouceCount[0]?.cgender == selfCount[0]?.cgender) {
            toast.error('Spouse relation is not  allowed.');
            return;
        }
        if (spouceCount[0]?.cgender == "" || selfCount[0]?.cgender == "") {
            toast.error('Spouse  not  allowed .');
            return;
        }
        if (dependentParents.length > 2) {
            toast.error('"Dependent parents already selected');
            return;
        }

        else {
            const payload = {
                client_code: client_code,
                client_type: client_type,
                family_declaration: validRecords.map((record) => ({
                    client_code: client_code?.trim() || "",
                    f_client_code: record.oowncode?.trim() || "",
                    name: record?.fibsacct,
                    dob: record.ddateofbirth?.trim() || "",
                    digio_id: record.digio_id?.trim() || null,
                    digio_response: record.digio_response?.trim() || null,
                    validate_status: record?.primary == 'Done' ? 0 : 1,
                    relation: record?.status == 3 ? null : record?.relation,
                    email: record.email,
                    mobile: record.mobile,
                    status: record?.primary == "Done" ? 3 : 1
                }))
            }
            console.log(payload, 'payload');

            setLoading(true)
            axios.post(DIGIO_FAMILY_SUBMITION, payload)
                .then(res => {
                    setLoading(false)
                    if (res.status) {
                        window.location.reload();
                    } else {
                        toast.error('something went wrong')
                    }
                })
                .catch(error => {
                    setLoading(false)
                    toast(error?.message || 'something went wrong')
                });
        };
    };


    const handleValidateAadhar = (item) => {
        const payload = {
            client_code: item?.oowncode.trim(),
            client_token: client_token,
            client_type: client_type,

        }
        const headers = {
            'Content-Type': 'application/json',
        };
        axios.post(OPEN_DIGILOKCKER_FAMILY, payload, { headers: headers })
            .then(res => {
                if (res?.status == true) {
                    setCheckStatus(true)
                    setData(item)
                    window.open(res?.link, '_blank')
                }
                else {
                    toast(res?.message)
                }
            })
            .catch(error => {
                toast(error?.message || 'something went wrong')
            });
    };

    const Tooltip = ({ text, children }) => {
        return (
            <div className="tooltip-container">
                {children}
                <div className="tooltip">{text}</div>
            </div>
        );
    };

    const columns = useMemo(() => [
        {
            Header: "Trading Code",
            accessor: "oowncode",

        },
        {
            Header: "Name",
            accessor: "fibsacct",
        },
        {
            Header: "DOB",
            accessor: "ddateofbirth",
        },

        {
            Header: "Remove",
            accessor: "f",
            Cell: ({ row }) => (
                (row.original.validate_status == 0) &&
                <div className="statement-search">
                    <input
                        type="checkbox"
                        checked={row.original.primary == 'Done' || row.original.status == '3'}
                        onChange={() => handleRemove(row.original)}
                    />
                </div>
            ),
        },

        {
            Header: "Relation",
            accessor: "relation",
            Cell: ({ row }) => (
                row.original.status == 0 || row.original.status == null ?
                    <div style={{ position: 'relative', display: 'inline-block', width: '180px' }}>
                        <select
                            className="form-control"
                            style={{ width: '100%' }}
                            value={accounts?.relation}
                            onChange={(e) => handleRelationChange(row.original.oowncode, e.target.value)}
                            disabled={row.original.validate_status == 0}

                        >
                            <option>Select</option>
                            {relationOptions.map(option => (
                                <option key={option.id} value={option.id}>
                                    {option.name}
                                </option>
                            ))}
                        </select>
                        <span className="dropdown-arrow" style={{
                            position: 'absolute',
                            right: '10px',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            pointerEvents: 'none'
                        }}>
                            ▼
                        </span>
                    </div>
                    :
                    row.original.relation == 1 ? 'Self' : row.original.relation == 2 ? 'Spouse' :
                        row.original.relation == 3 ? 'Dependent Parents' : row.original.relation == 4 ? 'Dependent Child'
                            : row.original.relation == 5 ? 'HUF' : 'Removed'
            ),
        },


        {
            Header: "Validate Aadhar",
            accessor: "validateAadhar",
            Cell: ({ row }) => (
                row?.original.status !== 3 ?
                    row?.original?.validate_status == 0 ? (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span
                                onClick={() => handleValidateAadhar(row?.original)}
                                className="click-here blue"
                                style={{ cursor: 'pointer', marginRight: '10px' }}
                            >
                                Click here
                            </span>
                            <Share1 newData={row?.original} />

                        </div>
                    ) : (
                        <p style={{ color: 'green', margin: 0 }}>Validated</p>
                    )
                    :
                    <p style={{ color: 'red', margin: 0 }}>Removed</p>
            ),
        },

        {
            Header: "Status",
            accessor: "status",
            Cell: ({ row }) => (
                row?.original?.status == 0 ?
                    <div style={{ display: 'flex' }}>
                        <h4 className="text-4 text-color10">{'Pending for submission'}</h4>
                    </div>
                    :
                    row?.original?.status == 1 ?
                        <h4 className="text-4 text-color10">{'Pending for review'}</h4>
                        :
                        row?.original?.status == 2 ?

                            <h4 style={{ color: 'green' }} className="text-4 ">{'Verified'}</h4>
                            :
                            row?.original?.status == 3 ?

                                <h4 style={{ color: 'red' }} className="text-4 ">{'Removed'}</h4>
                                :
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <h4 style={{ color: 'red' }} className="text-4 text-color10">{row.original.rejection?.reason ? 'Rejected' : 'not validated'}</h4>
                                    {
                                        row.original.rejection &&
                                        <Tooltip text={row.original.rejection?.reason} >
                                            <img src={eyes} style={{ height: '20px', width: '20px', marginLeft: '20px' }} />
                                        </Tooltip>
                                    }
                                </div>
            ),
        },
    ], []);

    const handleRemove = (record) => {
        setAccounts(prevData =>
            prevData.map(item => {
                if (item.oowncode === record?.oowncode) {
                    // Toggle primary: set to 'Done' if it was not set, or remove it (undefined) if it was
                    return {
                        ...item,
                        primary: item.primary ? undefined : 'Done'
                    };
                }
                return item;
            })
        );
    };


    const mobile = profileDetails[0]?.MOBILE.trim().slice(-4);
    const allStatusesOne = accounts.every(item => item.status == 1);

    if (loading) {
        return <Loader />;
    }

    return (
        <div className="login-wraper">
            <div>
                <div>
                    <p style={{ color: '#a3aed0' }} className="breadcrumb-item active main-header-secondary-page mb-4">
                        The Mobile number: <span style={{ fontWeight: 'bold' }}>XXXXXX{mobile}</span> and/or email <span style={{ fontWeight: 'bold' }}>{profileDetails[0]?.EMAIL.trim()}</span> you provided are linked to multiple trading accounts. Please update the relationships as per SEBI guidelines.
                    </p>
                    <TableContainer1
                        columns={columns}
                        data={accounts || []}
                        customPageSize={8}
                        divClass="table-responsive table-card mb-1"
                        tableClass="align-middle table-nowrap text-center"
                        theadClass="table-light text-muted text-uppercase fs-13"
                        tableTrClasses="summary-table-tr px-4"
                    />
                </div>
                {
                    validRecords.length > 0 && !allStatusesOne &&
                    validRecords.filter(record => record.primary !== 'Done').every(record => record.relation !== null) &&
                    <>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <button
                                type="button"
                                className="text-4 mb-2 btn btn-primary margin-overview-button1 mt-2"
                                style={{ borderRadius: "5px", height: "50%", width: '150px' }}
                                onClick={handleSubmit}
                            >
                                Submit
                            </button>

                        </div>
                    </>
                }
                {
                    accounts.length == 0 &&
                    <p style={{ color: '#a3aed0', textAlign: 'center' }} className="breadcrumb-item active main-header-secondary-page mb-2 mt-2">
                        No Records Avilable.
                    </p>
                }
                <p style={{ color: '#a3aed0', textAlign: 'center' }} className="breadcrumb-item active main-header-secondary-page mb-2 mt-2">
                    Please complete the process within 15 days; otherwise, all pending accounts will be deactivated.
                </p>
            </div>
        </div>
    );
};

export default FamilyTable;

// import React, { useEffect, useState } from 'react';
// import './Tooltip.css';
// import Loader from '../../Components/Common/Loader';
// import { useLocation } from 'react-router-dom';
// import { useSelector } from 'react-redux';
// import { toast } from 'react-toastify';
// import axios from 'axios';
// import { DIGIO_STATUS_FAMILY, MOD_EKYC_NEW, OPEN_DIGILOKCKER_FAMILY } from '../../helpers/url_helper';
// import Encrypted from '../Dashboard/Encripted';
// import Share1 from "../../Components/CustomComponents/Models/share/Share1";
// import Share from './../../../src/assets/images/user-icon.svg';

// const FamilyTable = () => {
//     const location = useLocation();
//     const client_code = localStorage.getItem('client_id');
//     const client_token = localStorage.getItem('client_token');
//     const client_type = localStorage.getItem('client_type');
//     const [accounts, setAccounts] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const { profileDetails } = useSelector((state) => ({
//         profileDetails: state.ProfileDetails.userProfile,
//     }));
//     const [checkStatus, setCheckStatus] = useState(false);
//     const [data1, setData] = useState([]);

//     const HUF = accounts?.filter(rel => rel?.relation === 'AOP');
//     const fourDigit = HUF[0]?.pangir.trim();
//     const fourthCharacter = fourDigit === undefined ? 'P' : fourDigit[3];
//     const selfCount = accounts.filter(account => account.relation === 'Self');
//     const spouceCount = accounts.filter(account => account.relation === 'Spouse');

//     const handleRelationChange = (id, newRelation) => {
//         if (selfCount.length == 0 && newRelation !== 'Self') {
//             toast.error('please select Self ')
//             return
//         }

//         if (newRelation === 'AOP' && fourthCharacter === "P") {
//             toast.error("For Relation AOP, Trading and demat account should be non-individual.");
//             setAccounts(prevContacts =>
//                 prevContacts.map(contact =>
//                     contact.oowncode === id ? { ...contact, relation: null } : contact
//                 )
//             );
//             return;
//         }
//         if (newRelation == 'Self' && selfCount.length >= 1) {
//             toast.error('Self already selected please select onother relation')
//             return
//         }
//         if (newRelation == 'Spouse' && spouceCount.length >= 1) {
//             toast.error('Spouse already selected please select onother relation')
//             return
//         }
//         setAccounts(prevContacts =>
//             prevContacts.map(contact =>
//                 contact.oowncode === id ? { ...contact, relation: newRelation } : contact
//             )
//         );
//     };

//     const mobile = profileDetails[0]?.MOBILE.trim().slice(-4);
//     const handleRemove = (record) => {
//         setAccounts(prevData =>
//             prevData.map(item => {
//                 if (item.oowncode === record?.oowncode) {
//                     // Toggle primary: set to 'Done' if it was not set, or remove it (undefined) if it was
//                     return {
//                         ...item,
//                         primary: item.primary ? undefined : 'Done'
//                     };
//                 }
//                 return item;
//             })
//         );
//     };
//     useEffect(() => {
//         let intervalId;
//         const callApi = () => {
//             const payload = {
//                 client_code: data1?.oowncode.trim(),
//                 client_token: client_token,
//                 client_type: client_type

//             }
//             const headers = {
//                 'Content-Type': 'application/json',

//             };
//             axios.post(DIGIO_STATUS_FAMILY, payload, { headers: headers })
//                 .then(res => {
//                     if (res?.status) {
//                         if (res?.digio_kyc_status !== 0 && data1 !== null) {
//                             setCheckStatus(false)
//                             fetchFamilyInfo()
//                         }
//                     }
//                     else {
//                         toast('something went wrong')
//                     }
//                 })
//                 .catch(error => {
//                     toast(error?.message || 'something went wrong')
//                 });
//         };
//         if (checkStatus && location.pathname.includes('family')) {
//             intervalId = setInterval(callApi, 20000); // Call API every 10 seconds
//         }
//         return () => {
//             clearInterval(intervalId); // Cleanup on unmount or condition change
//         };
//     }, [checkStatus]);

//     const handleValidateAadhar = (item) => {
//         if (item?.relation == 'null') {
//             toast.error('Please select a relation');
//             return;
//         }

//         else {
//             const payload = {
//                 client_code: item?.oowncode.trim(),
//                 client_token: client_token,
//                 client_type: client_type,
//             };

//             const headers = {
//                 'Content-Type': 'application/json',
//             };

//             axios.post(OPEN_DIGILOKCKER_FAMILY, payload, { headers: headers })
//                 .then(res => {
//                     if (res?.status === true) {
//                         setCheckStatus(true);
//                         setData(item);
//                         window.open(res?.link, '_blank');
//                     } else {
//                         toast(res?.message);
//                     }
//                 })
//                 .catch(error => {
//                     toast(error?.message || 'Something went wrong');
//                 });
//         }
//     };

//     // Fetch family information
//     const fetchFamilyInfo = async () => {
//         const payload = {
//             client_code: client_code,
//             mobile: profileDetails[0]?.MOBILE,
//         };

//         try {
//             const response = await axios.post(MOD_EKYC_NEW + '/get-family-info', { 'enc': Encrypted([payload]) });
//             if (response.status) {
//                 // Set accounts with initial relation value for each member
//                 const updatedAccounts = response?.data.map(member => ({
//                     ...member,
//                     relation: 'null',  // Default relation
//                 }));
//                 setAccounts(updatedAccounts);
//                 setLoading(false);
//             } else {
//                 toast('Something went wrong');
//                 setLoading(false);
//                 setAccounts([]);
//             }
//         } catch (err) {
//             toast('Something went wrong');
//             setAccounts([]);
//             setLoading(false);
//         }
//     };

//     // Run the fetchFamilyInfo when profileDetails is available
//     useEffect(() => {
//         setData([]);
//         setCheckStatus(false);
//         setLoading(true);
//         if (profileDetails[0]?.MOBILE?.length > 5) {
//             fetchFamilyInfo();
//         }
//     }, [profileDetails]);

//     if (loading) {
//         return <Loader />;
//     }
//     const newdata = accounts?.filter((item => item.status !== 3))
//     return (
//         <div className="card-conrainer">
//             <div className="card-row">
//                 <p style={{ color: '#a3aed0' }} className="breadcrumb-item active main-header-secondary-page mb-4">
//                     The Mobile number: <span style={{ fontWeight: 'bold' }}>XXXXXX{mobile}</span> and/or email <span style={{ fontWeight: 'bold' }}>{profileDetails[0]?.EMAIL.trim()}</span> you provided are linked to multiple trading accounts. Please update the relationships as per SEBI guidelines.
//                 </p>
//                 {accounts?.map((member) => (
//                     <div className="card-col" key={member.oowncode}>
//                         <div className="relation-cards">
//                             <div className="name-part">
//                                 <div className="left">
//                                     <div className="icon">
//                                         <img src={Share} style={{ height: '20px', width: '20px' }} />
//                                     </div>
//                                     <div>
//                                         <h5>{member?.fibsacct}</h5>
//                                         <p>{member?.oowncode}</p>
//                                     </div>
//                                 </div>
//                                 <div className={member.status === 1 ? 'pending' : member.status === 2 ? 'verified' : member.status === 3 ? 'reject' : 'pending'}>
//                                     <p>{member.status === 1 ? 'Pending for submission' : member.status === 2 ? 'Verified' : member.status === 3 ? 'Rejected' : 'Not validated'}</p>
//                                 </div>
//                             </div>

//                             <div className="relation-part">
//                                 <h4>Your relation</h4>
//                                 <div className="btn-part">
//                                     {['Self', 'Spouse', 'Dependent Parent', 'Dependent Child', 'HUF', 'AOP',].map((relation) => (
//                                         <label key={relation} className="rdo-btn">
//                                             <input
//                                                 type="radio"
//                                                 name={`radio-${member.oowncode}`}
//                                                 value={relation}
//                                                 checked={member.relation === relation}
//                                                 onChange={() => { handleRelationChange(member.oowncode, relation) }}
//                                                 disabled={member?.validate_status == 1}
//                                             />
//                                             <div className="checkmark">{relation}</div>
//                                         </label>
//                                     ))}
//                                 </div>
//                             </div>



//                             <div className="remove-part">
//                                 <label className="check-box">
//                                     <input
//                                         type="checkbox"
//                                         checked={member?.primary === 'Done' || member.status === '3'}
//                                         onChange={() => handleRemove(member)}
//                                     />
//                                     <div className="checkmark"></div>
//                                     Remove from family
//                                 </label>
//                             </div>

//                             <div className="pan-dob-part">
//                                 <div className="pan-box">
//                                     <h5>PAN :</h5>
//                                     <p style={{ marginLeft: '5px' }}>{member?.pangir}</p>
//                                 </div>
//                                 <div className="pan-box">
//                                     <h5>DOB: </h5>
//                                     <p style={{ marginLeft: '5px' }}>{member?.ddateofbirth}</p>
//                                 </div>
//                             </div>

//                             {member?.validate_status === 0 && member?.primary !== 'Done' &&
//                                 <div className="button-part">
//                                     <button className="btn" onClick={() => handleValidateAadhar(member)}>Click here</button>
//                                     <Share1 newData={member} />
//                                 </div>
//                             }
//                             {
//                                 member?.primary == 'Done' &&
//                                 <div className="button-part">
//                                     <button className="btn" onClick={() => alert()}>Submit</button>

//                                 </div>

//                             }

//                         </div>
//                     </div>
//                 ))}
//             </div>
//             <div style={{ height: '20px' }}></div>
//         </div>
//     );
// };

// export default FamilyTable;





